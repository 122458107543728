<template>
<CModalExtended
  :title="title"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  @update:show="$emit('close');"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow>
      <CCol sm="12">
        <CInput
          :label="$t('label.name')"
          :placeholder="$t('label.name')+' '+$t('label.groupingOfYards')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-5'}"
          addLabelClasses="required text-right"
          class="ml-1 w-100 float-left"
          v-uppercase
          v-model="$v.YardGpoName.$model"
          :invalid-feedback="errorMessage($v.YardGpoName)"
          :is-valid="hasError($v.YardGpoName)"
          />
      </CCol>
      <CCol sm="12" v-if="edit">
        <CSelect
          :options="statusOptions"
          :value.sync="Status"
          :label="$t('label.status')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-5'}"
          addLabelClasses="required text-right"
          class="float-right ml-1 w-100"
          :is-valid="statusSelectColor"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          size="sm"
          :fields="fields"
          :items="formatedItems"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          id="tableTrans"
            
        >
          <template #checked-filter>
            <CInputCheckbox
              :label="$t('label.all')"
              class="checkbook-filter"
              custom
              :checked="selectedAllYard"
              @update:checked="selectedAllYards($event)"
            />
          </template>
          <template #checked="{ item }">
            <td class="center-cell">
              <CInputCheckbox
                custom
                class="checkbook-filter"
                :checked="item.checked"
                :disabled = disableCheck(item)
                @update:checked="addRemoveId(item)"
              />
            </td>
          </template>
        </dataTableExtended>
        <p v-if="selectedYard && this.arrIds.length == 0" class="mt-2 text-danger">
          * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.yard')}}
        </p>
      </CCol>      
    </CRow>
  </form>
  <template #footer>
    <CButton
      square
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="edit ? statusConfirmation(groupingItems.FgActYardGpo, Status, submit) : submit()"
    >
    <CIcon name="checkAlt"/>{{$t('button.accept')}}
    </CButton>
    <CButton
      square
      color="wipe"
      class="d-flex align-items-center"
      @click="$emit('close');"
    >
      <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import { FormGpoYards } from '@/_validations/master-yards/MasterOfYards';
import { mapState } from 'vuex';
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//Data
function data() {
  return {
    modalActive: false,
    isSubmit: false,
    items: [],
    arrIdsOrigin: [],
    arrIds:[],
    selectedAllYard: false,
    YardGpoName: '',
    Status: 1,
    YardJson: [],
    Loading: false,
    YardGpoId: '',
    selectedYard: false
  }
}

//Methods
function selectedAllYards(event) {
  this.arrIds = [];
  if (event) {
    this.selectedAllYard = true;
    //SELECCIONA TODOS LOS ITEM NIVEL I
    this.items.map((item) => {
      if (!item.YardGpoDetailId) {
        this.arrIds.push(item);
      }else if( item.YardGpoId == this.YardGpoIdIdx && this.edit){
        this.arrIds.push(item); 
      }
    });
  }else{
    this.selectedAllYard = false;
  }
}
  function addRemoveId(item) {
   
    let bool = this.arrIds.filter((e) => e.YardId == item.YardId)

    if( bool.length == 0 ){
      //ADD
      this.arrIds.push(item);
      
    }
    else{
      //DELETE
      this.arrIds = this.arrIds.filter((e) => e.YardId !== item.YardId);

    }
    this.selectedAllYard = false;
     
    
  }


function YardGpoDetail_list() {
  this.Loading = true;
  this.$http
    .get('YardGpoDetail-list',{YardGpoId: this.YardGpoId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
        

        this.arrIdsOrigin = this.items.filter(item => item.YardGpoDetailId && item.YardGpoId!=this.YardGpoIdIdx); 

        if (this.edit) {
          this.arrIds = this.items.filter(item =>item.YardGpoId == this.YardGpoIdIdx);
        }
        
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}


function submit() {
  try {

    this.isSubmit = true;
    this.Loading = true;
    this.$v.$touch();
    this.selectedYard = true; 
    if (this.$v.$error || this.arrIds.length == 0) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let YardJson = this.arrIds.map((item) => {
      return {
        YardId: item.YardId,
      };
    })

    let YardGpoJson = this.edit ? {
      YardGpoId: this.YardGpoId,
      CompanyBranchId: this.getBranchId,
      YardGpoName: this.YardGpoName,
      Status: this.Status,
      YardJson: YardJson
    }:{
      YardGpoId: this.YardGpoId,
      CompanyBranchId: this.getBranchId,
      YardGpoName: this.YardGpoName,
      Status: this.Status,
      YardJson: YardJson
    };
    
  let metodo = this.edit ? 'POST':'POST';
  let ruta = this.edit ? 'YardGpo-insert': 'YardGpo-insert';
 
  this.$http.ejecutar(metodo, ruta, YardGpoJson, { root: 'YardGpoJson' })
    .then(response => {
      this.$emit('submited');
      this.YardGpoDetail_list();
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
      this.Loading = false;
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
    this.Loading = false;
  }
}

function getData(val) { 
  this.YardGpoId = val.YardGpoId;
  this.YardGpoName = val.YardGpoName;
  this.Status = val.FgActYardGpo ? 1:0;
  this.isSubmit = false;
  this.YardJson = val.DetailJson && val.DetailJson.length > 0 ? val.DetailJson.length : ''; 
  this.selectedYard = true; 
  this.$v.$touch();
}

function resetForm() {
  this.YardGpoId = '';
  this.YardGpoName= '';
  this.items = [];
  this.cleanDataSelect();
  this.$v.$reset();
}

function cleanDataSelect(){
  this.arrIds = [];
  this.arrIdsOrigin = [];
  this.items = [];
  this.selectedYard = false;
  this.selectedAllYard = false;
}



function statusSelectColor() {
  return this.Status === 1;
}

function disableCheck(item) {
  if (this.edit) {
    return this.arrIdsOrigin.some((e) => e.YardId == item.YardId && e.YardGpoId!=this.YardGpoIdIdx);
  }else{
    return this.arrIdsOrigin.some((e) => e.YardId == item.YardId);
  }
}




//Computeds:

function fields(){
  return  [
    { key: 'Nro', label: '#', filter: false,_style: 'width:5%; text-align:center;'},
    { key: 'YardName', label:this.$t('label.yard'), _style:'width:15%',_classes:"text-center"},
    { key: 'SquareMeters', label: 'AREA (M2)', _style: 'width:15%;',_classes:'text-center' },
    { key: 'Teus', label: ('TEUS'), _style: 'width:15%;',_classes:'text-center'},
    { key: 'YardGpoName', label:this.$t('label.group'), _style:'width:15%',_classes:"text-center"},
    { key: 'checked', label: this.$t('agridTable.applyFilter'), _style: 'width:3%', sorter: false, filter: false,_classes:"text-center"}
  ];
}


function formatedItems() {
  if(this.items == []){
    return this.items;
  }
  return this.items.map((item) => {
    let RequiredChecked = this.arrIdsOrigin.some((e) => e.YardId == item.YardId);
    let checked = this.arrIds.some((e) => e.YardId == item.YardId);
    return {
      ...item,
      YardGpoName: item.YardGpoName ?? 'N/A',
      SquareMeters: item.SquareMeters ? formatMilDecimal(item?.SquareMeters?.toFixed(2)) : 'N/A' ,
      _classes: item.Status == 'ACTIVO' && item.YardGpoId != this.YardGpoIdIdx  ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
      checked: RequiredChecked ? RequiredChecked : checked,
    };
  });
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}



export default {
  name: 'grouping-modal',
  mixins: [
    GeneralMixin,
    ModalMixin
  ],
  props: {modal: Boolean, edit: Boolean, title: String, groupingItems: Object, YardGpoIdIdx: String},
  data,
  validations(){ return FormGpoYards() },
  directives: UpperCase,
  methods: {
    selectedAllYards,
    addRemoveId,
    YardGpoDetail_list,
    submit,
    getData,
    resetForm,
    cleanDataSelect,
    statusSelectColor,
    disableCheck,
  },
  computed: {
    formatedItems,
    fields,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
    })
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
         this.YardGpoDetail_list();
      }
      if (this.edit==true) {
        this.getData(this.groupingItems);
      }else{
        this.resetForm();
      }
    }
  },
}
</script>
<style lang="scss">
/*
.center-cell-embalaje {
  text-align: center;
}*/
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.center-cell {
  text-align: center;
}
.table-index {
  margin: 1.5rem !important;
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>