<template>
    <CRow>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <groupingModal
        :modal.sync="modal"
        :groupingItems="groupingItems"
        :YardGpoIdIdx="YardGpoIdIdx"
        :edit="edit"
        :title="titleModal"
        @submited="handleSubmit"
        @close="closeModal"
      />
      <ViewYards
        :modal.sync="viewYardModal"
        :DetailJson="DetailJson"
        :title="titleModalView"
        @submited="handleSubmit"
        @close="closeModal"
      />
      <CCol sm="12">
              <CRow>
                <CCol sm="12" class="d-flex justify-content-end">
                  <CButton
                    color="add"
                    class="d-flex align-items-center"
                    v-c-tooltip="{
                      content: $t('label.yardGroup'),
                      placement: 'top-end'
                    }"
                    @click="toggleAdd"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                  </CButton>
                </CCol>
                <CCol col="12" xl="12">
                  <dataTableExtended 
                    class="align-center-row-datatable" 
                    hover 
                    sorter 
                    small 
                    column-filter
                    :table-filter="tableText.tableFilterText" 
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems" 
                    :fields="fields" 
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage" 
                    pagination 
                    >
                    <template #Status="{item}">
                      <td class="center-cell text-center">
                        <div>
                          <CBadge :color="getBadge(item.Status)">
                            {{$t('label.'+item.Status)}}
                          </CBadge>
                        </div>
                      </td>
                    </template>
                    <template #acciones="{ item }">
                      <td class="text-center">
                          <CButton
                            color="edit"
                            size="sm"
                            square
                            class="mr-1"
                            v-c-tooltip="{
                              content: $t('label.edit')+' '+$t('label.groupingOfYards'),
                              placement: 'top-end'
                            }"
                            @click="toggleEditYard(item)"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                          <CButton
                            color="watch"
                            square
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                              content: $t('label.toView') + ' '+$t('label.yardDetails'),
                              placement: 'top-end'
                            }"
                            @click="viewYard(item)"
                          >
                            <CIcon name="eye" />
                          </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
      </CCol>
    </CRow>
  </template>
  <script>
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import GeneralMixin from '@/_mixins/general';
  import groupingModal from './grouping-modal';
  import ViewYards from './view-yards-modal';
  import { formatMilDecimal } from '@/_validations/validacionEspeciales';
 
  //data
  function data() {
    return {
      Loading: false,
      items: [],
      modal: false,
      viewYardModal: false,
      edit: false,
      groupingItems: {},
      DetailJson: [],
      titleModal: '',
      titleModalView: '',
      YardGpoIdIdx: ''
    }
  }
  
  //Method
  function getGpoYards() {
    this.Loading = true;
    this.$http
      .get('YardGpoResum-list',{CompanyBranchId: this.CompanyBranchId})
      .then(response => {
        let listado = response.data.data;
        if (listado && listado.length != 0){
          this.items = listado;
        }
      })        
      .finally(() => {
        this.Loading = false;
      });
  }

  function toggleAdd() {
    this.modal = true;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.yardGroup');
  }

  function toggleEditYard(item) {
    this.groupingItems = item;
    this.YardGpoIdIdx = item.YardGpoId;
    this.edit = true;
    this.modal = true;
    let title = this.groupingItems.YardGpoName;
    this.titleModal= this.$t('label.edit')+' '+this.$t('label.yardGroup')+': '+title
  }
  
  function viewYard(item){
    this.viewYardModal = true;
    let array = item;
    this.DetailJson = array.DetailJson;
    let title = array.YardGpoName;
    this.titleModalView= this.$t('label.viewRelatedYards')+': '+title
  }

  function closeModal(){
    this.modal = false;
    this.viewYardModal = false;
    this.edit = false;
    this.titleModal = '';
    this.titleModalView = '';
    this.YardGpoIdIdx = '';
  }
  
  function handleSubmit() {
    this.getGpoYards();
  }
  
  //computed
  function fields() {
    return [
      { key: 'Nro', label: '#', _style: 'width:3%; text-align:left;', filter: false },
      { key: 'YardGpoName', label: this.$t('label.yardGroup'), _style: 'width:25%;',_classes:'text-center'},
      { key: 'YardQuantity', label: this.$t('label.quantity'), _style: 'width:15%;',_classes:'text-center'},
      { key: 'SquareMeters', label: 'AREA (M2)', _style: 'width:15%;',_classes:'text-center' },
      { key: 'Teus', label: ('TEUS'), _style: 'width:25%;',_classes:'text-center'},
      { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;', _classes: 'text-uppercase text-center' },
      { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;', _classes: "text-center" },
      { key: 'Status', label: this.$t('label.status'), _style: 'width:10%; text-align:center;', _classes: "text-center" },
      {
        key: 'acciones',
        label: '',
        _style: 'min-width:90px;',
        sorter: false,
        filter: false
      },
    ];
  }
  
  function formatedItems() {
    if(this.items == []){
      return this.items;
    }
    return this.items.map((item) => {
      return {
        ...item,
        SquareMeters: item?.SquareMeters ? formatMilDecimal(item.SquareMeters?.toFixed(2)) : 'N/A' ,
        UltimoUsuario: item?.TransaLogin ? item.TransaLogin : "N/A",
        FormatedDate: item?.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
        _classes: item.Status === "INACTIVO" ? "table-danger" : "",
      };
    });
  }

//watch
  export default {
    name: 'groupingOfYards-index',
    mixins:[GeneralMixin],
    components: {
      groupingModal,
      ViewYards
    },
    data,
    methods: {
      getGpoYards,
      toggleEditYard, 
      toggleAdd,
      closeModal,
      viewYard,
      handleSubmit,
    },
    computed: {
      formatedItems,
      fields,
      ...mapState({
        collapse: state => state.yard.collapse,
        CompanyBranchId: (state) => state.auth.branch.CompanyBranchId,
        tabIndex: state => state.yard.masterOfYardsTabIndex,
      })
    },
    watch: {
      tabIndex:function (val){
        if(val == 1){
          this.items = [];
          this.getGpoYards();
        }
      },
    }
  }
  </script>
  
  <style scoped>
  .center-cell {
    vertical-align: middle;
  }
  </style>