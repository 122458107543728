import { render, staticRenderFns } from "./groupingOfYards-index.vue?vue&type=template&id=224bfa34&scoped=true&"
import script from "./groupingOfYards-index.vue?vue&type=script&lang=js&"
export * from "./groupingOfYards-index.vue?vue&type=script&lang=js&"
import style0 from "./groupingOfYards-index.vue?vue&type=style&index=0&id=224bfa34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "224bfa34",
  null
  
)

export default component.exports